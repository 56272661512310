<template>
    <app-modal v-model="model">
        <template #title>
            <slot name="title">
                {{ title || $t('buttons.confirm') }}
            </slot>
        </template>
        <template #default>
            <slot name="default">
                {{ confirmBtnTxt || $t('buttons.confirmAction') }}
            </slot>
        </template>
        <template #actions>
            <cancel-btn @click="cancel">
                <slot name="cancelBtnText">
                    {{ cancelBtnTxt || $t('buttons.cancel') }}
                </slot>
            </cancel-btn>
            <app-primary-btn
                :color="confirmButtonColor"
                @click="confirm">
                <slot name="confirmBtnText">
                    {{ confirmBtnTxt || $t('buttons.confirm') }}
                </slot>
            </app-primary-btn>
        </template>
    </app-modal>
</template>

<script setup lang="ts">
type TProps = {
    title?: string;
    text?: string;
    confirmBtnTxt?: string;
    cancelBtnTxt?: string;
    confirmButtonColor?: string;
};

withDefaults(defineProps<TProps>(), {
    confirmButtonColor: 'primary',
    title: undefined,
    text: undefined,
    confirmBtnTxt: undefined,
    cancelBtnTxt: undefined,
});

type TEmits = {
    (e: 'cancel'): void;
    (e: 'confirm'): void;
};
const emits$ = defineEmits<TEmits>();

const model = defineModel<boolean>({
    required: true,
});

function confirm() {
    model.value = false;
    emits$('confirm');
}

function cancel() {
    model.value = false;
    emits$('cancel');
}
</script>
<style lang="scss" scoped></style>
